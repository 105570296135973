<template>
  <v-card
    color="secondary lighten-2"
    dark
  >
    <v-card-title class="text-h5 secondary lighten-3">
      Search available signs.
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        @change="setImage()"
        v-model="selected"
        color="white"
        hide-no-data
        hide-selected
        item-text="Description"
        item-value="signid"
        label="Sign Search"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
    
  <!--  <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!image"
        color="secondary darken-3"
        @click="image = null"
      >
        Clear
        <v-icon right>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-actions>-->
  </v-card>
</template>

<script>
  export default {
    props: ["image"],
    data: () => ({
      descriptionLimit: 50,
      signs: [],
      isLoading: false,
      search: null,
      selected: null
    }),
    methods: {
        setImage() {
            this.$emit('setImage', this.selected)
        }
    },
    computed: {        
      items () {
        return this.signs.map(sign => {
          var o = `[${sign.signcode}] ${sign.signdescription}`
          const Description = name.length > this.descriptionLimit
            ? o.slice(0, this.descriptionLimit) + '...'
            : o
          return Object.assign({}, sign, { Description })
        })
      },
    },

    watch: {
      search () {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch(`${process.env.VUE_APP_SIGN_API}/signs`)
          .then(res => res.json())
          .then(res => this.signs = res)
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
  }
</script>