<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8">
    <v-sheet outlined color="secondary" rounded>
      <v-card class="pa-4 text-center text-no-wrap rounded-sm"> 
        
        <v-row align="center">
          <v-col cols="12" sm="11">
            <span class="text-h4 text-md-h2 m-auto">Project Overview</span>
          </v-col>
        </v-row>

        <br /><br />
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-textarea outlined multi name="input-7-4" label="Submitted By" v-model="submittedby"></v-textarea>
          </v-col>
          <template v-if="autogenerated">
            <div class="d-none d-sm-block">
              <v-col cols="12" sm="1">
                <v-tooltip left color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span>This was automatically generated!</span>
                </v-tooltip>
              </v-col>
            </div>
          </template>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="10">
            <v-text-field v-model="formattedDate" label="Date" readonly outlined @focus="toggleCalendar(true)">
            </v-text-field>
          </v-col>
          <div class="d-none d-sm-block">
            <v-col cols="12" sm="1">
              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                </template>
                <span>This field is read only and can be changed by selecting date on calendar!</span>
              </v-tooltip>
            </v-col>
          </div>
        </v-row>

        <template v-if="calendarToggle">
          <v-date-picker v-model="date" @change="toggleCalendar(false)" color="primary"></v-date-picker>
        </template>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-text-field v-model="ordernumber" label="Order #" outlined clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-text-field v-model="contractidnumber" label="Contract ID No." outlined clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-text-field v-model="fhwa" label="FHWA" outlined clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-text-field v-model="projectid" label="Project" outlined clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-select :items="districtitems" outlined v-model="district" label="District"></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-textarea outlined name="input-7-4" label="Submitted To" v-model="submittedto"></v-textarea>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" sm="11">
            <v-text-field v-model="itemnumber" label="Item" outlined clearable></v-text-field>
          </v-col>
        </v-row>

      </v-card>
    </v-sheet>

    <br />
    <v-sheet outlined color="primary" rounded>
      <v-card outlined class="pa-4 text-center text-no-wrap rounded-sm">
        <span class="text-h5 text-md-h2 text-left">Add Sign To Document</span>
        <Sign :signs="signs" />
      </v-card>
    </v-sheet>

    <br />

    <v-row align="center">
      <v-col cols="12" sm="10">
        <v-text-field v-model="filename" label="Filename" outlined clearable></v-text-field>
      </v-col>
      <div class="d-none d-sm-block">
        <v-col cols="12" sm="1">
          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
            </template>
            <span>Change this to any name you want!</span>
          </v-tooltip>
        </v-col>
      </div>
    </v-row>
    <br />
    <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
    <v-overlay :value="submitted">
      <v-col cols="12">
      <v-progress-circular indeterminate size="270" color="primary">
        <v-img contain max-width="250" max-height="150" src="../assets/rt.png"></v-img>
      </v-progress-circular>
      </v-col>
    </v-overlay>
    </v-row>
    
      
    <v-btn @click="submitHandler" color="secondary">Generate PDF</v-btn>
    {{ this.signs.length }} sign(s) have been added.
  </v-col>
  </v-row>
  </v-container>
</template>

<script>
import {
  jsPDF
} from "jspdf";
import Sign from '../components/Sign.vue'
import vdotimage from "../assets/vdot.png"
import watermark from "../assets/wm-rt.png"
import moment from 'moment'

// import branding from "../assets/branding.png"
export default {
  name: 'Deliverable',
  components: {
    Sign
  },
  created() {
    var today = new Date();
    this.date = today.toISOString().substring(0,10);
    var t = moment(this.date).format("YYYYMMDD")
    this.filename = `document-${t}.pdf`
    if (this.$route.query.name) this.autogenerated = true
  },
  data() {
    return {
      autogenerated: false,
      ordernumber: 'ABC123',
      projectid: 'Project54321',
      fhwa: 'FHWA1234',
      contractidnumber: '0123456789',
      itemnumber: '50108',
      submittedto: 'Jane Doe, P.E.\nVDOT Richmond District\nRichmond, VA',
      submittedby: `${this.$route.query.name || 'John Smith'}\n${this.$route.query.company || 'Rivanna Technologies, LLC'}\n${this.$route.query.address || 'Ruckersville, VA 22968'}`,
      signs: [],
      vdotimage: vdotimage,
      filename: '',
      district: 'Richmond',
      // branding: branding,
      watermark: watermark,
      date: null,
      emailsending: false,
      generatingreport: false,
      submitted: false,
      districtitems: ['Bristol','Salem','Lynchburg','Richmond','Hampton Roads','Fredericksburg','Culpeper','Staunton','Northern VA'],
      calendarToggle: false
    }
  },
  computed: {
    formattedDate () {
      return moment(this.date).format("L")
    }
  },
  methods: {
    toggleCalendar(t) {
      this.calendarToggle = t
    },
    stringHelper(str) {
      const lineArr = str.split("\n");
      var finalString = "";
      lineArr.forEach(line => {
        const strArr = line.split(" ")
        var charCount = 0;
        strArr.forEach(word => {
          charCount += word.length;
          if (charCount >= 20) {
            finalString += "\n" + word + " ";
            charCount = word.length;
          } else {
            finalString += word + " ";
          }
        })
        finalString += "\n"
      })


      return finalString;
    },
    submitHandler() {
      if (this.signs.length) {
        this.submitted = true;
        setTimeout(function () {
          this.downloadPDF();
        }.bind(this), 1000)
      } else {
        this.$toast.warning("Add a sign first!");
      }

    },
    downloadPDF() {
        try{
        // this.$plausible.trackEvent('downloadpdf', { props: { num_signs_downloaded: this.signs.length }});
        this.$gtag.event('downloadpdf', { num_signs_downloaded: this.signs.length })
        const doc = new jsPDF({
          orientation: "landscape",
          format: [431.8, 279.4]
        });
        //cover
        doc.setFontSize(24);
        doc.text('Submittal Drawing For:', 90, 30);
        doc.text(`Order No.: ${this.ordernumber}`, 90, 49);
        doc.text(`Project: ${this.projectid}`, 90, 59);
        doc.text(`FHWA: ${this.fhwa}`, 90, 69);
        doc.text(`Contract ID No.: ${this.contractidnumber}`, 90, 79);
        doc.text(`Item ${this.itemnumber}`, 90, 89);

        doc.text(`Submitted To:`, 90, 111);
        doc.text(`${this.submittedto}`, 90, 129);

        doc.text(`Submitted By:`, 90, 185);
        doc.text(`${this.submittedby}`, 90, 203);

        doc.addImage(this.watermark, 'PNG', 0, 0, 431.8, 279.4, '','FAST')
        //
        //page
        this.signs.forEach((sign, index) => {
          doc.addPage([431.8, 279.4], "landscape");
          //lay down branding
          // doc.addImage(this.branding, 'PNG', 0, 0, 431.8, 279.4)   

          //add image first so we can write over top if needed
          //if reading from url we'll need to create new image.
          let u;
          
          //s3 presigned not working for some reason...
          const regex = /\?.*$/;
          const url = sign.url.replace(regex, '');
          if(sign.url.includes('blob:')){
            u = url;
          }else{
            u = new Image();
            u.crossOrigin = "anonymous";
            u.src = url;
          }
          doc.addImage(u, 'PNG', 20, 30, 193, 250, '','FAST');

          doc.setFontSize(14);
          doc.addImage(this.vdotimage, 'PNG', 9, 7, 36, 25, '','FAST');
          doc.setFont(undefined, 'bold');
          doc.text(`Order No.: ${this.ordernumber}   Project #: ${this.projectid}    FHWA: ${this.fhwa}    Contract ID. No.: ${this.contractidnumber}`, 50, 20);

          doc.setFontSize(10);
          var offset = 65;
          sign.sheetingcolors.forEach((color, idx) => {
            doc.setFont(undefined, 'bold');
            var i;
            sign.sheetingcolors.length > 1 ? i = `#${idx+1}` : i = '';
            doc.text(`Sheeting ${i}`, 250, offset)
            doc.setFont(undefined, 'normal');
            doc.text(`${sign.sheeting} ${color || ''}`, 275, offset);
            offset += 6;
          })

          sign.filmcolors.forEach((color, idx) => {
            doc.setFont(undefined, 'bold');
            var i;
            sign.filmcolors.length > 1 ? i = `#${idx+1}` : i = '';
            doc.text(`Film ${i}`, 250, offset)
            doc.setFont(undefined, 'normal');
            doc.text(`${sign.film} ${color || ''}`, 275, offset);
            offset += 6;
          })




          doc.setFont(undefined, 'bold');
          doc.text('Substrate', 250, offset)
          doc.text('Thickness', 250, offset + 6)
          doc.text('Sign Width', 250, offset + 12)
          doc.text('Mounting', 250, offset + 18)

          doc.setFont(undefined, 'normal');
          doc.text(`${sign.substrate}`, 275, offset);
          doc.text(`${sign.thickness} in.`, 275, offset + 6);
          doc.text(`${sign.width} in.`, 275, offset + 12);
          doc.text(`${sign.mounting || 'N/A'}`, 275, offset + 18);



          doc.setFontSize(16);
          doc.text('VIRGINIA DEPARTMENT OF', 282, 193);
          doc.text('TRANSPORTATION', 294, 200);
          doc.text('REVIEW OF WORKING DRAWINGS', 274, 207);
          doc.setFontSize(14);
          doc.text('Working drawings have been received in accordance', 254, 214);
          doc.text('with Section 105.02 of the Specifications with the', 254, 220);
          doc.text('following comments:', 254, 226);
          //
          doc.rect(255, 231, 5, 5);
          doc.text('Review Completed.', 263, 235);
          doc.rect(255, 237, 5, 5);
          doc.text('Correct and Resubmit.', 263, 241);
          doc.rect(255, 243, 5, 5);
          doc.text('Rejected See Remarks.', 263, 247);
          //
          doc.text('Reviewed by', 255, 260);
          doc.setLineWidth(1);
          doc.line(288, 260, 343, 260);
          doc.text('Date', 344, 260);
          doc.line(357, 260, 382, 260);
          doc.setLineWidth(0);
          if (this.district) doc.text(this.district.includes("Dist") ? `${this.district}` : `${this.district} Dist.`, 326, 235);


          //in graphic
          doc.setFontSize(14);
          doc.text(`${this.formattedDate}`, 400, 20)

          // var submittedToFinal = this.stringHelper(this.submittedto);
          // doc.text(`${submittedToFinal}`, 371, 110);
          //page num
          doc.setFontSize(18)
          doc.text(`${index+1}`, 410, 270);

          doc.setTextColor(0, 0, 0);

          doc.addImage(this.watermark, 'PNG', 0, 0, 431.8, 279.4, '','FAST')
        })
        doc.save(this.filename, {
          returnPromise: true
        })
          .then(this.submitted = false).then(this.$toast.success("Successfully Generated Document!"))
          
        
      }catch(err){
        this.submitted = false;
        this.$toast.error("Error! Try again later or contact the app administrator.");
      }
      }
      
    }
  
}
</script>

<style scoped>
</style>